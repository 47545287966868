
import { Vue, Component } from 'vue-property-decorator';
import { UserServiceClient } from '@/api/clients/userServiceClient';
const userServiceClient = new UserServiceClient();

@Component
export default class ValidateEmail extends Vue {
  public message: string = '';

  async created() {
    try {
      await userServiceClient.emailConfirm({
        username: this.$route.query.username.toString(),
        token: this.$route.query.validationToken.toString()
      });
      this.message =
        'Your email has been confirmed. You will be notified by email when your account has been approved.';
    } catch (err) {
      console.error(err.response);

      if (err.response && err.response.data !== '') {
        const responseData = JSON.parse(err.response.data);
        if (responseData.DisplayMessage && responseData.DisplayMessage !== '') {
          this.message = responseData.DisplayMessage;
        } else {
          this.message = 'There was an error validating your email address.';
        }
      } else {
        this.message = 'There was an error validating your email address.';
      }
    }
  }
}
